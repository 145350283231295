import { render, staticRenderFns } from "./EditMail.vue?vue&type=template&id=d6946424&scoped=true"
import script from "./EditMail.vue?vue&type=script&lang=js"
export * from "./EditMail.vue?vue&type=script&lang=js"
import style0 from "./EditMail.vue?vue&type=style&index=0&id=d6946424&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6946424",
  null
  
)

export default component.exports